<template>
  <div class="terms-price-list">

    <draggable-dynamic-table ref="termsPriceListTable"
                             :in-modal="true"
                             :active-grid="true"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="showTerm"
                             v-model="data"/>

    <!-- insert new user prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editTermPricePromptStatus"
      @close="editTermPricePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editTermPriceBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('terms.price.edit.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editTermPricePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <edit-term-price :term-id="termId" :in-modal="true" @insert="reloadCourtsList"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <button id="editTimePrice" v-show="false" @click="editTermPricePromptStatus = true"/>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getTimePrices} from '@/http/requests/timePrices'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import EditTermPrice from "../edit/editTermsPrice";
import { addComma } from "../../../../../assets/js/functions";

export default {
  name: 'TermsPriceList',
  metaInfo () {
    return {
      title: this.$t('terms.price.list.title')
    }
  },
  components: {
    EditTermPrice,
    CustomIcon,
    DraggableDynamicTable
  },
  props: {
    termId: 0
  },
  data () {
    return {
      requestSent: false,
      editTermPricePromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'termsPriceListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'day_7',
          i18n: 'terms.price.table.header.friday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_6',
          i18n: 'terms.price.table.header.thursday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_5',
          i18n: 'terms.price.table.header.wednesday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_4',
          i18n: 'terms.price.table.header.tuesday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_3',
          i18n: 'terms.price.table.header.monday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_2',
          i18n: 'terms.price.table.header.sunday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'day_1',
          i18n: 'terms.price.table.header.saturday',
          width: 'calc((100% / 10))',
          minWidth: 90,
        },
        {
          field: 'courtName',
          i18n: 'terms.price.table.header.courtName',
          width: '150px',
          minWidth: 120,
          align: 'center',
        }
      ],
      data: [],
      filters: [`term=${this.termId}`],
      sorts: ['order[0]=court_id,asc'],
      selectedUser: {},
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertNewCourt',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: 'user.create'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            // {
            //   id: {name: 'courtsTrash'},
            //   type: 'link',
            //   icon: 'icon-trash',
            //   iconPack: 'feather',
            //   permission: 'user.delete'
            // },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getTermPrices()
  },
  methods: {
    getTermPrices () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.termsPriceListTable && this.data.length === 0) {
              this.$refs.termsPriceListTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.termsPriceListTable && this.data.length > 0) {
              this.$refs.termsPriceListTable.loadMoreStatus = 'Loading'
            }

            getTimePrices(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              let data = {}
              res.data.data.forEach((term) => {
                if (term.court) {
                  if (!data[`court_${term.court.id}`]) {
                    data[`court_${term.court.id}`] = {}
                  }
                  data[`court_${term.court.id}`]['courtName'] = term.court.name || ''
                  data[`court_${term.court.id}`][`day_${term.week_day}`] = addComma(term.price)
                }
              })
              const dataArray = Object.values(data)
              setTimeout(() => {
                dataArray.forEach((item) => {
                  this.data.push(item)
                })
              }, 100)

              // this.page = res.data.pagination.current_page + 1
              // if (res.data.pagination.current_page === 1) {
              //   const row_index = this.columnsLabel.map((e) => {
              //     return e.field
              //   }).indexOf('row')
              //   this.columnsLabel[row_index].footer.value = res.data.pagination.total
              // }

              if (this.$refs.termsPriceListTable) this.$refs.termsPriceListTable.loadMoreStatus = ''

              this.requestSent = false
            })
              .catch((error) => {
                console.log(error)
                if (this.$refs.termsPriceListTable) this.$refs.termsPriceListTable.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    showTerm (user) {
      // this.selectedUser = user
      // this.showTermPromptStatus = true
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'accessGroup':
          if (filters[key].search !== '') filters_list.push(`role=${  filters[key].search}`)
          break

        case 'gender':
          if (filters[key].search.value > 0) filters_list.push(`gender=${  filters[key].search.value }`)
          break

        case 'phoneNumber':
          if (filters[key].search !== '') filters_list.push(`phone_number=${  filters[key].search}`)
          break

        case 'company':
          if (filters[key].search !== '') filters_list.push(`company=${  filters[key].search}`)
          break

        case 'name':
          if (filters[key].search !== '') filters_list.push(`full_name=${  filters[key].search}`)
          break

        case 'registryDate':
          if (filters[key].search !== '') filters_list.push(`created_at=${  filters[key].search}`)
          break
        }
      })
      filters_list.push(`term=${this.termId}`)

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getTermPrices()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

          /*case "accessGroup":
            sorts_list.push("order[0]=role," + (columns[key] ? 'desc' : 'asc'))
            break;*/

        case 'maxDebt':
          sorts_list.push(`order[0]=max_debt,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'phoneNumber':
          sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'company':
          sorts_list.push(`order[0]=company,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'registryDate':
          sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'group':
          sorts_list.push(`order[0]=group,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'gender':
          sorts_list.push(`order[0]=gender,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=court_id,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getTermPrices()
    },
    reloadCourtsList () {
      this.data = []
      this.page = 1
      this.endedList = false
      this.getTermPrices()
      this.editTermPricePromptStatus = false
    },
    consoleLog () {
      // console.log(event)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.usersChanged': {
      handler (val) {
        if (val) {
          this.page = 1
          this.endedList = false
          this.filters = []
          this.sorts = ['order[0]=created_at,desc']
          this.data = []
          this.editTermPricePromptStatus = false
          this.getTermPrices()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .terms-price-list {
    height: 100%;
  }
</style>
